import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import styles from "./BlogTemplate.module.scss";

const BlogTemplate = ({ pageContext }) => {
  const {
    title,
    image,
    childContentfulPostsContentRichTextNode,
  } = pageContext.data;

  // console.log(childContentfulPostsContentRichTextNode.json)

  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        console.log(node);
        return (
          <div className={styles.imageContainer}>
            <img
              src={`https://${node.data.target.fields?.file[`en-US`].url}`}
              height={
                node.data.target.fields?.file[`en-US`].details.image.height
              }
              width={node.data.target.fields?.file[`en-US`].details.image.width}
              alt={node.data.target.fields?.description}
            />
          </div>
        );
      },
    },
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.imageWrap}>
          <img src={image.fluid.src} className={styles.image} alt={title} />
        </div>
        <div className={styles.content}>
          {documentToReactComponents(
            childContentfulPostsContentRichTextNode.json,
            renderOptions
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogTemplate;
